.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(88, 138, 77);
  flex-direction: column;
}

.container a {
  margin-top: 20px;
  width: 250px;
  height: 50px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-weight: 700;
}

.container h1 {
  font-size: 32px;
  margin-bottom: 40px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
}
